import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section } from "./dataPolicy"
import styled from "styled-components"

export const Headline = styled.div`
  font-size: 60px;
  line-height: 90px;
`

const LegalPage = () => {
  const images = useStaticQuery(graphql`
    query {
      header: file(relativePath: { eq: "legal-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout
      smallHeader={true}
      title={"RECHTLICHES"}
      image={images.header.childImageSharp.fluid}
    >
      <SEO title="Impressum" description={"Wer steckt hinter MainOrder?"} />
      <Section>
        <Headline>Impressum</Headline>
        <p>Registernummer: HRB 120464 </p>
      </Section>
    </Layout>
  )
}

export default LegalPage
